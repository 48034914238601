import styled from '@emotion/styled/macro';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerIcon from '@mui/icons-material/Event';
import { forwardRef } from 'react';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { TextField } from './TextField';
import { ReactDatePickerProps } from 'react-datepicker';
import { formatDate } from 'utils/helpers';

export const CustomDatePickerInput = forwardRef(function CustomDatePickerInput(
  props: TextFieldProps
) {
  const isDatePickerIconVisible = !props.value && !props.disabled;
  return (
    <TextField
      {...props}
      fullWidth
      type="button"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isDatePickerIconVisible ? <DatePickerIcon /> : null}
          </InputAdornment>
        ),
      }}
    />
  );
});

export function PrimaryDatePicker(props: ReactDatePickerProps) {
  return (
    <Container>
      <DatePicker
        {...props}
        showYearDropdown
        showMonthDropdown
        autoComplete="off"
        calendarStartDay={1}
        dropdownMode="select"
        dateFormat="dd/MM/yyyy"
        popperPlacement="bottom"
        disabledKeyboardNavigation
        customInput={<CustomDatePickerInput />}
        isClearable={props.disabled ? false : true}
        todayButton={`Today is ${formatDate(new Date())}`}
      />
    </Container>
  );
}

export function PrimaryDateTimePicker(props: ReactDatePickerProps) {
  return (
    <Container>
      <DatePicker
        {...props}
        showTimeSelect
        showYearDropdown
        showMonthDropdown
        timeIntervals={15}
        autoComplete="off"
        timeFormat="h:mm aa"
        calendarStartDay={1}
        dropdownMode="select"
        popperPlacement="bottom"
        disabledKeyboardNavigation
        dateFormat="dd/MM/yyyy h:mm aa"
        customInput={<CustomDatePickerInput />}
        isClearable={props.disabled ? false : true}
        todayButton={`Today is ${formatDate(new Date())}`}
      />
    </Container>
  );
}

const Container = styled.div`
  & .MuiInputBase-input {
    text-align: start;
    cursor: pointer;
  }
  & .react-datepicker-popper {
    z-index: 9999 !important;
  }
  & .react-datepicker__close-icon {
    height: 100%;
    margin-right: ${({ theme }) => theme.margin.xs};
    ::after {
      cursor: pointer;
      background-color: transparent;
      color: ${({ theme }) => theme.palette.button.dark};
      font-size: 30px;
    }
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: ${({ theme }) => theme.padding.s};
    cursor: pointer;
  }
  & .react-datepicker-wrapper {
    display: flex;
    padding: 0;
    border: 0;
  }
  & .react-datepicker {
    font-family: Inter,sans-serif;
    background-color: ${({ theme }) => theme.palette.button.light};
    .react-datepicker__day {
      :hover {
        background-color: ${({ theme }) => theme.palette.button.hover};
        color: ${({ theme }) => theme.palette.primary.light};
      }
    }
    & .react-datepicker__day--selected {
      background-color: ${({ theme }) => theme.palette.button.dark};
    }
    & .react-datepicker__day--keyboard-selected {
      background-color: ${({ theme }) => theme.palette.button.dark};
      color: ${({ theme }) => theme.palette.primary.light};
    }
    & .react-datepicker__day--today {
      font-weight: unset;
    }
  }
  & .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  & .react-datepicker__time {
    background-color: ${({ theme }) => theme.palette.button.light};
    & .react-datepicker__time-box 
      ul.react-datepicker__time-list {
        li.react-datepicker__time-list-item {
          &:hover {
            background-color: ${({ theme }) => theme.palette.button.hover};
            color: ${({ theme }) => theme.palette.primary.light};
          }
          &--selected {
            background-color: ${({ theme }) => theme.palette.button.dark};
          }
        }
      }
    }
  }
`;
