import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  accessToken?: string;
  name?: string;
  email?: string | unknown;
  activeUserProfileId?: number;
}

export interface ImpersonatedUser {
  accessToken?: string;
  name?: string;
  email?: string;
  activeUserProfileId?: number;
  userId?: number;
}

export interface AuthState {
  user?: User;
  isLoading: boolean;
  impersonatedUser?: ImpersonatedUser | undefined;
  isImpersonating: boolean;
}

const initialAuthState: AuthState = {
  isLoading: true,
  isImpersonating: true,
};

const slice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    userLoggedin: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    },
    userLoginFailed: (state) => {
      return { ...state, user: undefined, isLoading: false };
    },
    userLoggedout: (state) => {
      return { ...state, user: undefined, isLoading: false };
    },
    userLoadingStarted: (state) => {
      return { ...state, isLoading: true };
    },
    userAccessTokenExpired: (state) => {
      return { ...state, user: undefined, isLoading: false };
    },
    userSwitchedProfile: (state, action: PayloadAction<number>) => {
      state.user = { ...state.user, activeUserProfileId: action.payload };
    },

    //impersonated user
    impersonatedUserSwitchedProfile: (state, action: PayloadAction<number>) => {
      state.impersonatedUser = {
        ...state.impersonatedUser,
        activeUserProfileId: action.payload,
      };
    },
    userImpersonated: (state, action: PayloadAction<ImpersonatedUser>) => {
      return {
        ...state,
        isImpersonating: false,
        impersonatedUser: action.payload,
      };
    },
    userImpersonationFailed: (state) => {
      return { ...state, impersonatedUser: undefined, isImpersonating: false };
    },
    userImpersonationRemoved: (state) => {
      return { ...state, impersonatedUser: undefined, isImpersonating: false };
    },
    userImpersonationStarted: (state) => {
      return { ...state, isImpersonating: true };
    },
    impersonatedAccessTokenExpired: (state) => {
      return { ...state, impersonatedUser: undefined, isImpersonating: false };
    },
  },
});

export default slice.reducer;

export const {
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userLoadingStarted,
  userAccessTokenExpired,
  userSwitchedProfile,
  userImpersonated,
  userImpersonationStarted,
  userImpersonationFailed,
  userImpersonationRemoved,
  impersonatedAccessTokenExpired,
  impersonatedUserSwitchedProfile,
} = slice.actions;
