import ErrorBoundary from 'core/ErrorBoundary';
import { Provider } from 'react-redux';
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';

import store from 'core/store';
import Main from './Main';
import EmotionTheme from 'core/Theming/EmotionTheme';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'api/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MainContainer } from 'components/Layout';
//import AuthProvider from 'core/auth/AuthProvider';
//import userManager from 'core/auth/userService';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from 'core/ScrollToTop';
import { MaintenanceMessages } from 'core/maintenanceMessages';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ScrollContextProvider from 'core/ScrollContext';
import IdleTimerWrapper from 'core/IdleTimer';
import { RouterContextProvider } from 'core/PageData';
import { reactPlugin } from 'core/logger/ApplicationInsightsService';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
// MSAL imports
import { msalInstance } from 'core/auth/userService';

// type AppProps = {
//   pca: IPublicClientApplication;
// };

function App() {
  return (
    <EmotionTheme>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          {/* <AuthProvider userManager={userManager} store={store}> */}
          <MsalProvider instance={msalInstance}>
            <IdleTimerWrapper>
            <AppInsightsContext.Provider value={reactPlugin}>
              <MainContainer>
                <ErrorBoundary>
                  <MaintenanceMessages>
                    <ToastContainer />
                    <HashRouter>
                      <ScrollToTop />
                      <ScrollContextProvider>
                        <RouterContextProvider>
                          <Main />
                        </RouterContextProvider>
                      </ScrollContextProvider>
                    </HashRouter>
                  </MaintenanceMessages>
                </ErrorBoundary>
              </MainContainer>
              </AppInsightsContext.Provider>
            </IdleTimerWrapper>
          </MsalProvider>
          {/* </AuthProvider> */}
        </Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </EmotionTheme>
  );
}

export default App;
