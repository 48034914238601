import { logger } from 'core/logger';
import store from 'core/store';
import { queryClient } from 'api/client';
import {
  userLoadingStarted,
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userSwitchedProfile,
  userImpersonated,
  impersonatedUserSwitchedProfile,
  userImpersonationStarted,
  userImpersonationFailed,
  userImpersonationRemoved,
} from 'core/store/auth';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { config } from '../config';
import jwt_decode from 'jwt-decode';
import { getRepairersCacheKey, getUserCashKey } from 'pages/hooks';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import {
  PublicClientApplication,
  EventType,
  AccountInfo,
  SilentRequest,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../../../src/core/config";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(async (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      const account = event.payload;

      msalInstance.setActiveAccount(account as AccountInfo);
      const userAccount = msalInstance.getActiveAccount();
      const request: SilentRequest = {
        ...loginRequest,
        account: userAccount!,
      };
  
      try {
        // Silently acquires an access token which is then attached to a request for API access
        const response = await msalInstance.acquireTokenSilent(request);
        store.dispatch(
          userLoggedin({
            accessToken: response.accessToken,
            name: userAccount?.idTokenClaims?.name,
            email: userAccount?.idTokenClaims?.email,
          })
        );
      } catch (e) {
        console.log(e);
      }
    }
  });
});

const userManager = new UserManager({
  authority: config.authority,
  client_id: config.clientId,
  redirect_uri: config.redirectUri,
  response_type: config.responseType,
  scope: config.scope,
  post_logout_redirect_uri: config.postLogoutRedirectUri,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri: config.silentSigninUri,
});

export const localStorageSelectedProfileKey = 'selectedProfileId';
export const localStorageImpersonatedProfileKey =
  'selectedImpersonatedProfileId';
export const localStorageImpersonatedUser = 'impersonatedUser';
export const localStorageImpersonatedUserId = 'impersonatedUserId';

export async function loadUserFromStorage() {
  try {
    const isUserImpersonated = localStorage.getItem(
      localStorageImpersonatedUser
    );
    const impersonatedUserId = localStorage.getItem(
      localStorageImpersonatedUserId
    );
    if (isUserImpersonated && impersonatedUserId) {
      updatedImpersonateUser(
        JSON.parse(isUserImpersonated),
        Number(impersonatedUserId)
      );
      const impersonatedProfileId = localStorage.getItem(
        localStorageImpersonatedProfileKey
      );
      if (impersonatedProfileId) {
        switchImpersonatedUserProfile(Number(impersonatedProfileId));
      }
    } else {
      store.dispatch(userImpersonationFailed());
    }

    store.dispatch(userLoadingStarted());
    
    const user = msalInstance.getActiveAccount();
    if (!user) {
      return store.dispatch(userLoginFailed());
    }
    
    let accessToken = store.getState().auth.user?.accessToken;
    //acquiring access token if not exists in store
    if(!accessToken){
      try {
        const userAccount = user;
        const request: SilentRequest = {
          ...loginRequest,
          account: userAccount!,
        };
        const response = await msalInstance.acquireTokenSilent(request);
        accessToken = response.accessToken;
      } catch (error) {
        console.log(error);
      }
    }
    
    store.dispatch(
      userLoggedin({
        accessToken: accessToken,
        name: user?.idTokenClaims?.name,
        email: user?.idTokenClaims?.email,
      })
    );
    const profileId = localStorage.getItem(localStorageSelectedProfileKey);

    if (profileId) {
      switchUserProfile(Number(profileId));
    }
  } catch (e) {
    logger?.error(`UserService -> User not found: ${e}`);
    appInsights?.trackException({ error: new Error(`UserService -> User not found: ${e}`), severityLevel: SeverityLevel.Error });
    store.dispatch(userLoginFailed());
  }
}

export function clearUserManagerUserState() {
  userManager.clearStaleState();
  userManager.removeUser();
  localStorage.removeItem(localStorageSelectedProfileKey);
}

export function signinRedirect() {
  store.dispatch(userLoadingStarted());
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function silentSigninCallback() {
  return userManager.signinSilentCallback();
}

export function signoutRedirectCallback() {
  clearUserManagerUserState();
  return userManager.signoutRedirectCallback();
}

window.addEventListener('storage', function(event){
  if (event.key === 'logout-event') { 
    signout();
  }
});

export function signout(message?: string) {
  store.dispatch(userLoggedout());
  queryClient.clear(); 
  clearStorage();
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: "/",
  };
  if (message) {
    //TODO: Pass message to Msal logout ???
    return   msalInstance.logoutRedirect(logoutRequest);
  }
    // return userManager.signoutRedirect({
    // //return msalInstance.logoutRedirect({
    //   extraQueryParams: {
    //     reason: message,
    //   },
    // });
  else      
      return   msalInstance.logoutRedirect(logoutRequest);
}

export function clearStorage() {
  store.dispatch(userImpersonationRemoved());
  localStorage.removeItem(localStorageImpersonatedUser);
  localStorage.removeItem(localStorageImpersonatedUserId);
  localStorage.removeItem(localStorageImpersonatedProfileKey);
  msalInstance.clearCache();
  queryClient.invalidateQueries(getUserCashKey());
  queryClient.invalidateQueries(getRepairersCacheKey());
  queryClient.clear();
}

export function userImpersonate(userInfo: AuthenticationResult, userId: number) {
  localStorage.setItem(
    localStorageImpersonatedUser,
    JSON.stringify(userInfo)
  );
  localStorage.setItem(localStorageImpersonatedUserId, userId.toString());
  updatedImpersonateUser(userInfo, userId);
}

export function updatedImpersonateUser(
  userInfo: AuthenticationResult,
  userId: number
) {
  if (userInfo.accessToken) {
    store.dispatch(userImpersonationStarted());
    const decoded: { name: string; impersonatedUser: string } = jwt_decode(
      userInfo.accessToken
    );
    store.dispatch(
      userImpersonated({
        accessToken: userInfo.accessToken,
        // name: decoded.name ?? undefined,
        email: decoded.impersonatedUser,
        userId: userId,
      })
    );
    userManager.clearStaleState();
    msalInstance.clearCache();
    queryClient.invalidateQueries(getUserCashKey());
    queryClient.invalidateQueries(getRepairersCacheKey());
    queryClient.clear();
  }
}

export function removeImpersonateUser() {
  store.dispatch(userImpersonationRemoved());
  localStorage.removeItem(localStorageImpersonatedUser);
  localStorage.removeItem(localStorageImpersonatedUserId);
  localStorage.removeItem(localStorageImpersonatedProfileKey);
  userManager.clearStaleState();
  msalInstance.clearCache();
  window.location.reload();
  queryClient.invalidateQueries(getUserCashKey());
  queryClient.invalidateQueries(getRepairersCacheKey());
  queryClient.clear();
}

export function switchUserProfile(profileId: number | undefined) {
  store.dispatch(userSwitchedProfile(Number(profileId)));
  if (profileId)
    localStorage.setItem(
      localStorageSelectedProfileKey,
      profileId?.toString()
    );
}

export function switchImpersonatedUserProfile(profileId: number | undefined) {
  store.dispatch(impersonatedUserSwitchedProfile(Number(profileId)));
  if (profileId)
    localStorage.setItem(
      localStorageImpersonatedProfileKey,
      profileId?.toString()
    );
}
export function clearLocalStorage() {
  localStorage.removeItem(localStorageSelectedProfileKey);
  if (localStorage.getItem(localStorageImpersonatedUser)) {
    localStorage.removeItem(localStorageImpersonatedUser);
    localStorage.removeItem(localStorageImpersonatedUserId);
    localStorage.removeItem(localStorageImpersonatedProfileKey);
  }
}

export default userManager;
