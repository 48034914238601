import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { convertToDate, useServiceAccounts, useWorkProvidersWithSms, useCombinedNpsSmsEmailBillingReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
//import { ROUTES } from 'core/routes';
import { ReportProps } from '../hooks';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import {
  COMBINED_BILLING_REPORT_GRID_TITLE,
  COMBINED_BILLING_REPORT_MESSAGE,
  COMBINED_BILLING_REPORT_TITLE,
  columns,
  dateColumnsName,
  summaryColumns
} from './constants';
import { createReportURL } from 'core/routes';
//import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { DateOnlyFormatter } from 'components/ReportsComponents/DateOnlyFormatter';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { WorkProviderSearchForm } from 'components/ReportForms/WorkProviderSearchForm';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';
import { WorkProviderGroup } from 'api/resources/models/AutoGenerated';
import { ReportsLoader } from 'components/ReportsLoader';

export function CombinedNpsSmsEmailBillingReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    startDate: urlParams.get('startDate'),
    endDate: urlParams.get('endDate'),
    wPGroupNames: urlParams.get('workProvider'),
  };

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) ?? null
  );
  const [workProvider, setWorkProvider] = useState<string | null>(
    urlParamsValue.wPGroupNames || null
  );
  const { workProviders, isWorkProvidersLoading } = useWorkProvidersWithSms(
    (error) => handleError( error, 'isWorkProvider' )
  );
  const options = workProviders?.map((e: WorkProviderGroup, i: number) => {
    return {
      id: i,
      value: e.groupName,
      text: e.workProvider,
    };
  });

  const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate && !!urlParamsValue.wPGroupNames;

  const { reportData, refetchReport, isReportFetching } =
  useCombinedNpsSmsEmailBillingReport(
    {
      startDate: urlParamsValue.startDate,
      endDate: urlParamsValue.endDate,
      wPGroupNames: urlParamsValue.wPGroupNames,
    },
    queryEnabled,
    (error: any) => handleError(error, 'isWorkProvider')
  );
  
  const rows = useMemo<Row>(() => reportData, [reportData]);

  const summaryRows = useMemo<Row>(() => rows?.data?.summary, [rows?.data?.summary]);
  const detailsRows = useMemo<Row>(() => rows?.data?.details, [rows?.data?.details]);

  const isBusy = isReportFetching;
  const isOptionsLoading = isWorkProvidersLoading;

  useEffect(() => {
    navigateToReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
  ]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={COMBINED_BILLING_REPORT_TITLE}
          infoMessage={COMBINED_BILLING_REPORT_MESSAGE}
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
        <WorkProviderSearchForm
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          isDisabled={isReportFetching}
          workProvider={workProvider}
          setWorkProvider={setWorkProvider}
          options={options}
        />
        )}
      </UpperInfoContainer>
      {!isBusy && detailsRows && detailsRows?.length === 0 && <NoReportDataMessage />}
      <div style={{ paddingBottom: 20}}>     
        <ReportGridContainer>
          <ReportGrid
            rows={summaryRows}
            columns={summaryColumns}
            disableFiltering={true}
            disabledGrouping={true}
            disableDragAndDrop={true}
            disabledTableSelection={true}
            hideExportPanel={true}
            noPagination={true}
            isLoading={isBusy}
            title={"SummaryByWorkProvider"}            
          />
        </ReportGridContainer>
      </div>
      <div>
        <ReportGridContainer direction="column">
          <ReportGrid
            rows={detailsRows}
            columns={columns}
            isLoading={isBusy}
            dateColumnsName={dateColumnsName}
            DateOnlyFormatter={DateOnlyFormatter}
            customizeCellExport={customizeCell}
            title={COMBINED_BILLING_REPORT_GRID_TITLE}
            headerTitleForSheet={COMBINED_BILLING_REPORT_TITLE}
            reportHeaderTagLine={`Report produced on ${formatDate(
              new Date()
            )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
            pdfOrientation='l'
            showAdditionalExportOptions
            valuesConverter={valuesConverter}
            hideExportToCSV={true}
          />
        </ReportGridContainer>
      </div>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.wPGroupNames == workProvider
    )
    refetchReport();
    else navigateToReport();
  }

  function navigateToReport() {
    if (!!startDate && !!endDate && !!workProvider)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            workProvider: workProvider,
          },
          reportPath
        )
      );
  }

  function handleError(
    error: AxiosError,
    type: 'isReport' | 'isWorkProvider',
 ) {
    reportLogger(error);
    setErrorMessage(
      type == 'isReport' 
      ? errorMessages.reports('Combined NPS, SMS and Email Billing Report - PPG Internal') 
      : errorMessages.workProviderAdded  
    );
    setToast(true);
  }
}
