import {
  Clear,
  LockOpen,
  MailOutline,
  Security,
  SupervisorAccount,
} from '@mui/icons-material';
import { UserAccount } from './types';
import { GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Label } from 'components/PageLayout';
import {
  ActionButton,
  ActionText,
  InnerStatusContainer,
  ItemWrapper,
  Line,
  MainStatusContainer,
  StatusHeader,
} from 'components/PageStatusLayout';
import { Text } from 'components/Typography';
import { logger } from 'core/logger';
import {
  useDisableUser,
  useEnableUser,
  useNotifyUser,
  useResetPassword,
  useUnlockUser,
  useUser,
} from 'pages/hooks';
import { formatDateTime } from 'utils/helpers';
import { userImpersonate } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'core/store';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { b2cPolicies } from "../../../src/core/config";
import jwt_decode from 'jwt-decode';

interface Props {
  user: UserAccount;
  onUserChange: (successMessage: string) => void;
  onUserAction: (successMessage: string) => void;
}

export function UserSecurityInfo({ user, onUserChange, onUserAction }: Props) {
  const history = useHistory();
  const { disableUser, isDisabling } = useDisableUser();
  const { enableUser, isEnabling } = useEnableUser();
  const { unlockUser, isUnlocking } = useUnlockUser();
  const { resetPassword, isReseting } = useResetPassword();
  const { notifyUser, isNotifying } = useNotifyUser();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance } = useMsal();
  const { isImpersonating } = useAppSelector((state) => state.auth);
  const { impersonatedUser } = useAppSelector((state) => state.auth);
  const { user: loggedInUser } = useUser();

  const isBusy =
    isDisabling ||
    isEnabling ||
    isUnlocking ||
    isReseting ||
    isNotifying ||
    isImpersonating;

  const acquireImpersonationToken = async (userEmail: string) => {  
    if (isAuthenticated && inProgress === InteractionStatus.None) { 
      // to send user email to ADB2C to acquire a token response
      const redirectRequest = b2cPolicies.authorities.impersonate;
      redirectRequest.extraQueryParameters.targetEmail = userEmail;
      await instance.acquireTokenRedirect(redirectRequest);
    } 
  }
  const handleRedirect = async () => { 
    // handle impersonation token and redirect to impersonated user's dashboard
    const response = await instance.handleRedirectPromise();
    if (response) {  
      const decodeResponse : { impersonatedUser: string  } = jwt_decode(
        response?.accessToken
      );
      if (decodeResponse.impersonatedUser === user.email) {
        userImpersonate(response, user.id);
        history.push(ROUTES.dashboard);
      }
    }
  }

  useEffect(() => {
    // Handle redirect on mount
    handleRedirect();
  });
  return (
    <MainStatusContainer md={8} xs={12} direction="column">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <GridItem>
        <InnerStatusContainer direction="column">
          <StatusHeader>Security Details</StatusHeader>
          <ItemWrapper>
            <Label>Password Status</Label>
            <Text fontSize="l" noWrap>
              {user.hasPassword
                ? 'User has password'
                : "User doesn't have password"}
            </Text>
          </ItemWrapper>
          <ItemWrapper>
            <Label>Locked Status</Label>
            <Text fontSize="l">
              {user.isLocked ? 'User is locked' : 'User is not locked'}
            </Text>
          </ItemWrapper>
          {user.lastLoginDate && (
            <ItemWrapper>
              <>
                <Label>Last Login</Label>
                <Text fontSize="l">{formatDateTime(user.lastLoginDate)}</Text>
              </>
            </ItemWrapper>
          )}
          <ItemWrapper>
            <Label>Login Count</Label>
            <Text fontSize="l">{user.usageCount}</Text>
          </ItemWrapper>
          <GridItem>
            <Label>Failed Login Count</Label>
            <Text fontSize="l">{user.invalidLoginAttempts}</Text>
          </GridItem>
        </InnerStatusContainer>
      </GridItem>

      <GridItem>
        <InnerStatusContainer direction="column">
          <StatusHeader>Security Actions</StatusHeader>
          <GridItem>
            <ActionButton
              disabled={
                !user.email ||
                user.isDisabled ||
                user.isLocked ||
                !!impersonatedUser
              }
              onClick={async () => {
                if (
                  user.email &&
                  !user.isDisabled &&
                  !user.isLocked &&
                  !impersonatedUser
                )
                  await acquireImpersonationToken(user.email);
              }}
            >
              <ActionText>
                <SupervisorAccount />
                Impersonate User Account
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              onClick={() => {
                if (user.email)
                  notifyUser(user.email).then(() =>
                    onUserAction(successMessages.userNotification)
                  );
              }}
            >
              <ActionText>
                <MailOutline />
                Notify User
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              onClick={() => {
                if (user.email)
                  resetPassword(user.email).then(() =>
                    onUserAction(successMessages.userPasswordReset)
                  );
              }}
            >
              <ActionText>
                <Security />
                Request Password Reset
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              disabled={user.id === loggedInUser?.userId}
              onClick={() =>
                user.isDisabled
                  ? createActionHandler(
                      () => enableUser(user.id),
                      successMessages.userEnabled
                    )
                  : createActionHandler(
                      () => disableUser(user.id),
                      successMessages.userDisabled
                    )
              }
            >
              <ActionText>
                <Clear />
                {user.isDisabled
                  ? 'Enable User Account'
                  : 'Disable User Account'}
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              disabled={!user.isLocked}
              onClick={() =>
                createActionHandler(
                  () => unlockUser(user.id),
                  successMessages.userUnlocked
                )
              }
            >
              <ActionText>
                <LockOpen />
                Unlock User Account
              </ActionText>
            </ActionButton>
          </GridItem>
        </InnerStatusContainer>
      </GridItem>
    </MainStatusContainer>
  );

  function createActionHandler<T>(
    getPromise: () => Promise<T>,
    successMessage: string
  ) {
    return getPromise()
      .then(() => {
        onUserChange(successMessage);
      })
      .catch((error) => {
        logger?.error(error)
        appInsights?.trackException({ error, severityLevel: SeverityLevel.Error });
      });
  }
}
