import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Done } from '@mui/icons-material';
import { PrimaryButton } from './Button';
import { Container, GridItem } from './Layout';
import { Switch } from '@mui/material';

export const pagePaddingLeft = (theme: Theme) => theme.padding.xl;
export const pagePaddingRight = (theme: Theme) => theme.padding.xl;

export const MainPageContainer = styled(Container)<{
  isLoading?: boolean;
}>`
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
  & .MuiTableContainer-root {
    overflow-x: visible;
  }
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-right: ${({ theme }) => pagePaddingRight(theme)};
  }
`;

export const UpperInfoContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.background.default};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

export const StyledGridItem = styled(GridItem)`
  padding-top: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-top: ${({ theme }) => ` ${theme.padding.m}`};
  }
`;

// width: 144px;
export const SaveButton = styled(PrimaryButton)`
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  margin-bottom: ${({ theme }) => theme.margin.l};
  padding: ${({ theme }) => theme.padding.s} 12px;
`;

export const SaveIcon = styled(Done)`
  margin-right: ${({ theme }) => theme.margin.s};
`;

export const Label = styled.header`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.margin.xs};
`;

export const RequiredLabel = styled(Label)`
  ::after {
    content: '*';
    padding-left: 2px;
    color: ${({ theme }) => theme.palette.icon.red};
  }
`;

export const InputFirst = styled(GridItem)`
  padding-right: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-right: 0;
    padding-bottom: 12px;
  }
`;

export const InputSecond = styled(GridItem)`
  padding-left: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-left: 0;
    padding-bottom: 12px;
  }
`;

export const PageHeader = styled(GridItem)`
  padding-bottom: ${({ theme }) => theme.padding.l};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-bottom: ${({ theme }) => theme.padding.m};
  }
`;

export const Header = styled.header`
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

export const InputContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.l};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 0;
  }
`;

export const MainInputContainer = styled(Container)`
  padding-top: ${({ theme }) => theme.padding.l};
`;

export const TableContainer = styled(Container)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-bottom: 60px;
  }
`;

export const ToggleSwitch = styled(Switch)(() : any => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      left: 12,
    },
    '&:after': {
      right: 12,
    },
  },
}));

