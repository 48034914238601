
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect } from "react";
import { ROUTES } from 'core/routes';
import { useAppSelector } from 'core/store';
import { Redirect } from 'react-router';
import { loginRequest } from 'core/config';

export  function Login() {
  
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = useAppSelector((state) => state.auth.user);

  const doLogin = async () => {
    if (!user && !isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  }
 
  useEffect(() => {
    doLogin();
  }, [instance, isAuthenticated, inProgress]);

  return user ? <Redirect to={ROUTES.redirectHandler} /> : null;
}