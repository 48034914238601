import { Container, GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import {
  SaveIcon,
  SaveButton,
  Header,
  InputContainer,
  InputFirst,
  InputSecond,
  RequiredLabel,
  Label,
  ToggleSwitch,
} from 'components/PageLayout';
import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';
import { TextField } from 'components/TextField';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateTimePickerField } from 'components/DatePickerFields';
import dayjs from 'dayjs';
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import { Company } from './form';
import { useMemo } from 'react';
import { formSchema } from './formSchema';
import { Text } from 'components/Typography';
import { IP_DUPLICATED_MESSAGE } from './constants';


interface Props {
  company?: Company;
  isBusy: boolean;
  onSubmit: (data: Company) => void;
}

export function CompaniesDetails({ company, onSubmit, isBusy }: Props) {
  const companyInfo = useMemo(() => company ?? null, [company]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: companyInfo?.id ?? 0,
      name: companyInfo?.name ?? '',
      autoMainReference: companyInfo?.autoMainReference ?? false,
      lastMainReference: companyInfo?.lastMainReference ?? 0,
      mainReferencePrefix: companyInfo?.mainReferencePrefix ?? '',
      poP3Host: companyInfo?.poP3Host ?? '',
      poP3Port: companyInfo?.poP3Port ?? null,
      poP3UserName: companyInfo?.poP3UserName ?? '',
      poP3Password: companyInfo?.poP3Password ?? '',
      poP3UseSSL: companyInfo?.poP3UseSSL ?? false,
      smtpHost: companyInfo?.smtpHost ?? '',
      smtpPort: companyInfo?.smtpPort ?? null,
      smtpReplyTo: companyInfo?.smtpReplyTo ?? '',
      smtpDisplayName: companyInfo?.smtpDisplayName ?? '',
      urlExtension: companyInfo?.urlExtension ?? '',
      configPath: companyInfo?.configPath ?? '',
      logTable: companyInfo?.logTable ?? '',
      workStart: companyInfo?.workStart ?? null,
      workEnd: companyInfo?.workEnd ?? null,
      locale: companyInfo?.locale ?? '',
      validSourceIP: companyInfo?.validSourceIP ?? [{ ip: '*' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'validSourceIP',
    control,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <Container direction="column">
        <GridItem>
          <StyledSaveButton type="submit">
            <SaveIcon />
            Save Changes
          </StyledSaveButton>
        </GridItem>
        <Header>Company Details</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <RequiredLabel>Company Name</RequiredLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.name}
                  fullWidth
                  helperText={errors.name?.message}
                  onChange={(e) => {
                    setValue('name', e.target.value);
                  }}
                />
              )}
              name="name"
              control={control}
            />
          </InputFirst>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Work Start</Label>
            <Controller
              render={({ field }) => {
                return (
                  <DateTimePickerField
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                      if (getValues('workStart')) {
                        return dayjs(value).isAfter(getValues('workStart'))
                          ? setValue('workStart', null)
                          : null;
                      }
                    }}
                  />
                );
              }}
              name="workStart"
              control={control}
            />
            {!!errors.workStart && (
              <SelectHelperText>{errors.workStart.message}</SelectHelperText>
            )}
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Work End</Label>
            <Controller
              render={({ field }) => {
                return (
                  <DateTimePickerField
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                      if (getValues('workEnd')) {
                        return dayjs(value).isAfter(getValues('workEnd'))
                          ? setValue('workEnd', null)
                          : null;
                      }
                    }}
                  />
                );
              }}
              name="workEnd"
              control={control}
            />
            {!!errors.workEnd && (
              <SelectHelperText>{errors.workEnd.message}</SelectHelperText>
            )}
          </InputSecond>
        </InputContainer>
        <Header>Valid Source IP</Header>
        <InputContainer direction="row">
          <InputFirst lg={8} md={8} sm={8} xs={12}>
            {fields.map((element, index) => (
              <CustomDiv key={element.id}>
                <Controller
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      fullWidth
                      value={field.value.ip}
                      error={
                        !!errors.validSourceIP?.[index]?.ip &&
                        errors.validSourceIP?.[index]?.ip?.message !== IP_DUPLICATED_MESSAGE
                      }
                      placeholder={`IP Address ${index + 1}`}
                      helperText={errors.validSourceIP?.[index]?.ip?.message}
                      onChange={(e) => {
                        setValue(`validSourceIP.${index}`, {
                          ip: e.target.value,
                        });
                      }}
                    />
                  )}
                  name={`validSourceIP.${index}`}
                  control={control}
                />
                {fields?.length - 1 == index && (
                  <AddIcon onClick={() => append({ ip: '' })} />
                )}
                {fields?.length > 1 && (
                  <RemoveIcon onClick={() => remove(index)} />
                )}
              </CustomDiv>
            ))}
            {errors.validSourceIP?.[errors.validSourceIP?.length !== undefined ? errors.validSourceIP?.length - 1 : 0]?.ip
              ?.message === IP_DUPLICATED_MESSAGE ? (
              <Text
                fontSize="s"
                color="#d32f2f"
              >{IP_DUPLICATED_MESSAGE}</Text>
            ) : null}
          </InputFirst>
        </InputContainer>
        <Header>Reference Details</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Main Reference Prefix</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.mainReferencePrefix}
                  fullWidth
                  helperText={errors.mainReferencePrefix?.message}
                  onChange={(e) => {
                    setValue('mainReferencePrefix', e.target.value);
                  }}
                />
              )}
              name="mainReferencePrefix"
              control={control}
            />
          </InputFirst>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Auto Main Reference</Label>
            <Controller
              render={({ field }) => (
                <StyledSwitch>
                  <ToggleSwitch
                    {...field}
                    onChange={(e) => {
                      setValue('autoMainReference', !e.target.value);
                    }}
                  />
                </StyledSwitch>
              )}
              name="autoMainReference"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Last Main Reference</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  error={!!errors.lastMainReference}
                  helperText={errors.lastMainReference?.message}
                  onChange={(e) => field.onChange(Number(e.target.value || 0))}
                />
              )}
              name="lastMainReference"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <Header>POP3 Details</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>POP3 Host</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.poP3Host}
                  fullWidth
                  helperText={errors.poP3Host?.message}
                  onChange={(e) => {
                    setValue('poP3Host', e.target.value);
                  }}
                />
              )}
              name="poP3Host"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>POP3 Port</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  type="number"
                  error={!!errors.poP3Port}
                  helperText={errors.poP3Port?.message}
                  onChange={(e) => field.onChange(Number(e.target.value || 0))}
                />
              )}
              name="poP3Port"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>POP3 Username</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.poP3UserName}
                  fullWidth
                  helperText={errors.poP3UserName?.message}
                  onChange={(e) => {
                    setValue('poP3UserName', e.target.value);
                  }}
                />
              )}
              name="poP3UserName"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>POP3 Password</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  error={!!errors.poP3Password}
                  helperText={errors.poP3Password?.message}
                  onChange={(e) => {
                    setValue('poP3Password', e.target.value);
                  }}
                />
              )}
              name="poP3Password"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>POP3 Use SSL</Label>
            <Controller
              render={({ field }) => (
                <StyledSwitch>
                  <ToggleSwitch
                    {...field}
                    onChange={(e) => {
                      setValue('poP3UseSSL', !e.target.value);
                    }}
                  />
                </StyledSwitch>
              )}
              name="poP3UseSSL"
              control={control}
            />
          </InputFirst>
        </InputContainer>
        <Header>SMTP Details</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>SMTP Host</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.smtpHost}
                  fullWidth
                  helperText={errors.smtpHost?.message}
                  onChange={(e) => {
                    setValue('smtpHost', e.target.value);
                  }}
                />
              )}
              name="smtpHost"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>SMTP Port</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  type="number"
                  error={!!errors.smtpPort}
                  helperText={errors.smtpPort?.message}
                  onChange={(e) => field.onChange(Number(e.target.value || 0))}
                />
              )}
              name="smtpPort"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>SMTP Reply To Email</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.smtpReplyTo}
                  fullWidth
                  helperText={errors.smtpReplyTo?.message}
                  onChange={(e) => {
                    setValue('smtpReplyTo', e.target.value);
                  }}
                />
              )}
              name="smtpReplyTo"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>SMTP Display Name</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  error={!!errors.smtpDisplayName}
                  helperText={errors.smtpDisplayName?.message}
                  onChange={(e) => {
                    setValue('smtpDisplayName', e.target.value);
                  }}
                />
              )}
              name="smtpDisplayName"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <Header>Config Details</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>URL Extension</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.urlExtension}
                  fullWidth
                  helperText={errors.urlExtension?.message}
                  onChange={(e) => {
                    setValue('urlExtension', e.target.value);
                  }}
                />
              )}
              name="urlExtension"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Config Path</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  error={!!errors.configPath}
                  helperText={errors.configPath?.message}
                  onChange={(e) => {
                    setValue('configPath', e.target.value);
                  }}
                />
              )}
              name="configPath"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Log Table</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.logTable}
                  fullWidth
                  helperText={errors.logTable?.message}
                  onChange={(e) => {
                    setValue('logTable', e.target.value);
                  }}
                />
              )}
              name="logTable"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Locale</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  error={!!errors.locale}
                  helperText={errors.locale?.message}
                  onChange={(e) => {
                    setValue('locale', e.target.value);
                  }}
                />
              )}
              name="locale"
              control={control}
            />
          </InputSecond>
        </InputContainer>
      </Container>
    </form>
  );
}

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
`;

const SelectHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.messages.error};
`;

const StyledSwitch = styled.div`
  margin-left: -${({ theme }) => theme.margin.s};
`;

const AddIcon = styled(AddCircle)`
  margin-left: ${({ theme }) => theme.margin.s};
  margin-top: ${({ theme }) => theme.margin.s};
`;
const RemoveIcon = styled(RemoveCircleSharpIcon)`
  margin-left: ${({ theme }) => theme.margin.s};
  margin-top: ${({ theme }) => theme.margin.s};
`;
const CustomDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.margin.l};
`;
const CustomTextField = styled(TextField)`
  max-width: 74.5%;
`;
