/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { SvgIconComponent } from '@mui/icons-material';
import { UserPermit } from 'api/resources/models/AutoGenerated';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HelpInformationModal } from '../HelpInformationModal';
import { mapMenuItems } from './helpers';
import LegendModal from './LegendModal';
import { bottomMenuItems, MenuItem, Mode } from './types';

interface Props {
  userPermits: UserPermit[];
  topMenuItems: MenuItem[];
}

export function DesktopMenu({ userPermits, topMenuItems }: Props) {
  return (
    <ListContainer>
      <div>
        {mapMenuItems(topMenuItems, userPermits).map((item, index) => {
          return (
            <ListItem
              className="container"
              css={ListItemStyles}
              component={NavLink}
              to={item.route}
              key={index}
              activeClassName="active-menu-item"
            >
              <div>
                <Icon>{renderIcon(item.icon)}</Icon>
                <IconText isActive={false}>{item.name}</IconText>
              </div>
            </ListItem>
          );
        })}
      </div>
      <div>
        <BottomMenuItems />
      </div>
    </ListContainer>
  );
}

function renderIcon(
  Icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
) {
  return <Icon />;
}

function BottomMenuItems() {
  const [showModal, setShowModal] = useState<Mode>(null);
  return (
    <>
      {bottomMenuItems.map((item, index) => {
        return (
          <ListItem
            className="container"
            key={index}
            css={ListItemStyles}
            onClick={() => {
              if (item.name === 'Help') return setShowModal('Help');
              setShowModal('Legend');
            }}
          >
            {/* eslint-disable-next-line react/no-unknown-property */}
            <div css={ContainerStyles}>
              <Icon>{renderIcon(item.icon)}</Icon>
              <IconText isActive={false}>{item.name}</IconText>
            </div>
          </ListItem>
        );
      })}
      {showModal === 'Help' && (
        <HelpInformationModal isOpen onCloseClick={() => setShowModal(null)} />
      )}
      {showModal === 'Legend' && (
        <LegendModal isOpen onCloseClick={() => setShowModal(null)} />
      )}
    </>
  );
}

const ListItemStyles = (theme: Theme) => css`
  background-color: ${theme.palette.background.dark};

  &:hover {
    background-color: ${theme.palette.drawer.hoverColor};
  }
`;

const IconText = styled('p')<{ isActive: boolean }>`
  color: ${({ theme }) => theme.palette.menuIcons.default};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  margin-top: 0px;
  text-align: center;

  &.MuiListItemIcon-root {
    min-width: unset;
  }
`;

const ContainerStyles = (theme: Theme) => css`
  height: ${theme.palette.drawer.height}px;
  overflow-wrap: break-word;
`;

const Icon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.menuIcons.default};
  margin-top: ${({ theme }) => theme.margin.xs};
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  &.MuiListItemIcon-root {
    min-width: unset;
  }
`;

const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .active-menu-item {
    background-color: ${({ theme }) => theme.palette.drawer.hoverColor};
    ${IconText} {
      color: ${({ theme }) => theme.palette.menuIcons.active};
    }

    ${Icon} {
      color: ${({ theme }) => theme.palette.menuIcons.active};
      path {
        fill: ${({ theme }) => theme.palette.menuIcons.active};
      }
    }
  }

  .container {
    padding-left: ${({ theme }) => theme.padding.s};
    padding-right: ${({ theme }) => theme.padding.s};
    justify-content: center;
  }
`;
