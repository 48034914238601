import React, { createContext, useContext, useState } from 'react';

type PageData = {
  previousPathName: string;
  previousSearch: string;
};

type ContextType = {
  pageData: PageData | null;
  setPageData: React.Dispatch<React.SetStateAction<PageData | null>>;
};

const PageDataContext = createContext<ContextType | undefined>(undefined);

export const RouterContextProvider = ({ children }: {children: React.ReactNode}) => {
  const [pageData, setPageData] = useState<PageData | null>(null);

  return (
    <PageDataContext.Provider value={{ pageData, setPageData }}>
      {children}
    </PageDataContext.Provider>
  );
};

export const usePageData = (): ContextType => {
  const context = useContext(PageDataContext);
  if (!context) {
    throw new Error('usePageData must be used within a PageDataProvider');
  }
  return context;
};
