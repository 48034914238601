/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { SvgIconComponent } from '@mui/icons-material';
import { UserPermit } from 'api/resources/models/AutoGenerated';
import { HelpInformationModal } from 'components/HelpInformationModal';
import { Container } from 'components/Layout';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { mapMenuItems } from './helpers';
import LegendModal from './LegendModal';
import { bottomMenuItems, Mode, topMenuItems } from './types';

interface Props {
  userPermits: UserPermit[];
  setOpen: () => void;
}

export function MobileMenu({ setOpen, userPermits }: Props) {
  return (
    <MainContainer>
      <Items>
        {mapMenuItems(topMenuItems, userPermits).map((item, index) => {
          return (
            <ListItem
              css={ListItemStyles}
              component={NavLink}
              to={item.route}
              onClick={setOpen}
              key={index}
              activeClassName="active-menu-item"
            >
              <Icon>{renderIcon(item.icon)}</Icon>
              <IconText>{item.name}</IconText>
            </ListItem>
          );
        })}
        <MobileBottomMenuItems />
      </Items>
    </MainContainer>
  );
}

function renderIcon(
  Icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
) {
  return <Icon />;
}

function MobileBottomMenuItems() {
  const [showModal, setShowModal] = useState<Mode>(null);
  return (
    <>
      {bottomMenuItems.map((item, index) => {
        return (
          <ListItem
            className="container"
            key={index}
            css={ListItemStyles}
            onClick={() => {
              if (item.name === 'Help') return setShowModal('Help');
              setShowModal('Legend');
            }}
          >
            <Icon>{renderIcon(item.icon)}</Icon>
            <IconText>{item.name}</IconText>
          </ListItem>
        );
      })}
      {showModal === 'Help' && (
        <HelpInformationModal isOpen onCloseClick={() => setShowModal(null)} />
      )}
      {showModal === 'Legend' && (
        <LegendModal isOpen onCloseClick={() => setShowModal(null)} />
      )}
    </>
  );
}

const IconText = styled('p')`
  color: ${({ theme }) => theme.palette.menuIcons.default};
  font-size: ${({ theme }) => theme.fontSize.s};
  margin-left: ${({ theme }) => theme.margin.m};
`;

const Icon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.menuIcons.default};

  &.MuiListItemIcon-root {
    min-width: unset;
  }
`;

const MainContainer = styled(Container)`
  display: none;
  position: absolute;
  top: 45px;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.background.dark};
  z-index: 999;
  flex-direction: row;

  .active-menu-item {
    background-color: ${({ theme }) => theme.palette.drawer.hoverColor};

    ${IconText} {
      color: ${({ theme }) => theme.palette.menuIcons.active};
    }

    ${Icon} {
      color: ${({ theme }) => theme.palette.menuIcons.active};
    }
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: flex;
  }
`;

const Items = styled.div`
  width: 100%;
`;

const ListItemStyles = (theme: Theme) => css`
  height: 48px;
  background-color: ${theme.palette.background.dark};

  &:hover {
    background-color: ${theme.palette.drawer.hoverColor};
  }
`;
